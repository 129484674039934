<template>
  <div id="configure">
    <div class="header-title">{{ $route.meta.title }}</div>
    <Loading :show="loading||loading1">
      <el-row>
        <el-col :span="24" :md="12">
          <div class="next-card-title fLwpMi">图片配置</div>
          <el-form
            ref="formImage"
            label-position="top"
            :model="formImage"
            :rules="rulesImage"
            label-width="140px"
            class="formImage"
          >
            <el-form-item label="" prop="name">
              <template slot="label">
                登录页背景图
                <el-tooltip content="仅支持jpg,png,jpeg文件格式，且不超过2M" placement="bottom" effect="light">
                  <i style="font-size:14px" class="el-icon-question" />
                </el-tooltip>

                <el-button type="text" @click="editUpload('login')">重新选择</el-button>
              </template>
              <el-image
                v-if="formImage.LoginBackground"
                style="width: 500px; height:300px"
                :src="BASE_URL+formImage.LoginBackground"
                :preview-src-list="[BASE_URL+formImage.LoginBackground]"
              />
            </el-form-item>
            <el-form-item label="" prop="name">
              <template slot="label">
                浏览器标签栏图标
                <el-tooltip content="仅支持jpg,png,jpeg,ico文件格式，且不超过512k" placement="bottom" effect="light">
                  <i style="font-size:14px" class="el-icon-question" />
                </el-tooltip>
                <el-button type="text" @click="editUpload('browser')">重新选择</el-button>
              </template>

              <el-image
                v-if="formImage.BrowserIcon"
                style="width: 50px; height: 50px"
                :src="BASE_URL + formImage.BrowserIcon"
                :preview-src-list="[BASE_URL + formImage.BrowserIcon]"
              />
            </el-form-item>
            <el-form-item label="顶部导航栏顶部图标" prop="name">
              <template slot="label">
                顶部导航栏顶部图标
                <el-tooltip content="仅支持jpg,png,jpeg文件格式，且不超过512k" placement="bottom" effect="light">
                  <i style="font-size:14px" class="el-icon-question" />
                </el-tooltip>
                <el-button type="text" @click="editUpload('top')">重新选择</el-button>
              </template>
              <el-image
                v-if="formImage.TopNavigationIcon"
                style="width: 50px; height: 50px"
                :src="BASE_URL+formImage.TopNavigationIcon"
                :preview-src-list="[BASE_URL+formImage.TopNavigationIcon]"
              />
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="24" :md="12">
          <div class="next-card-title fLwpMi">文字配置</div>
          <el-form
            ref="formImage"
            label-position="top"
            :model="formText"
            :rules="rulesText"
            label-width="140px"
            class="demo-ruleForm"
          >
            <el-form-item label="浏览器标签文字" prop="title">
              <el-input
                v-model="formText.browser_name"
                placeholder="请输入"
                style="width: 400px"
                clearable
                maxlength="20"
                show-word-limit
              />
              <el-button v-if="formText.browser_name!=browser_name" type="text" @click="save(formText.browser_name,'browser_name')">保存</el-button>
            </el-form-item>
            <el-form-item label="顶部导航栏左侧文字" prop="title">
              <el-input
                v-model="formText.top_nav_name"
                placeholder="请输入"
                style="width: 400px"
                clearable
                maxlength="20"
                show-word-limit
              />
              <el-button v-if="formText.top_nav_name!=top_nav_name" type="text" @click="save(formText.top_nav_name,'top_nav_name')">保存</el-button>
            </el-form-item>
            <el-form-item label="左侧导航栏跟结点文字" prop="title">
              <el-input
                v-model="formText.left_nav_name"
                placeholder="请输入"
                style="width: 400px"
                clearable
                maxlength="20"
                show-word-limit
              />
              <el-button v-if="formText.left_nav_name!=left_nav_name" type="text" @click="save(formText.left_nav_name,'left_nav_name')">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </Loading>
    <el-upload accept="jpeg" action="" :http-request="upload" :show-file-list="false">
      <el-button v-show="false" size="mini" type="warning" plain />
    </el-upload>
  </div>
</template>

<script>
import { set_system_text, set_system_image } from '@/api'
import { mapActions } from 'vuex'
import config from '@/config'
import Bus from '@/utils/Bus'

export default {
  inject: ['reload'],
  data () {
    return {
      formImage: {},
      rulesImage: {},
      formText: {},
      rulesText: {},
      srcList: [],
      loading: false,
      loading1: false,
      type: '',
      browser_name: '',
      top_nav_name: '',
      left_nav_name: '',
      BASE_URL: config.img_base_url
    }
  },
  mounted () {
    this.get_image()
    this.get_title()

    console.log(this.BASE_URL, this.formImage.LoginBackground, 'script Log')
  },
  methods: {
    ...mapActions([
      'getMenuList',
      'getSystemInfo',
      'getSystemInfoImage'
    ]),
    editUpload (type) {
      this.type = type
      document.querySelector('.el-upload button.el-button').click()
    },
    // 上传图片
    upload (file) {
      this.loading = true
      const formData = new FormData()
      formData.append('image', file.file)
      formData.append('image_type', this.type)
      set_system_image(formData).then(res => {
        this.loading = false
        this.get_image()
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'success'
        })
      }).catch(() => {
        this.loading = false
      })
    },
    save (val, type) {
      const data = { content: val, text_type: type }
      set_system_text(data).then(res => {
        if (!res.code) {
          Bus.$emit('*_title')
          this.get_title()
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'success'
          })
        }
      })
    },
    get_image () {
      this.loading = true
      this.getSystemInfoImage().then(res => {
        this.loading = false
        this.$nextTick(() => {
          this.formImage = res.data
        })
      }).catch(() => {
        this.loading = false
      })
    },
    get_title () {
      this.loading1 = true
      this.getSystemInfo().then(res => {
        this.loading1 = false
        this.browser_name = res.data.browser_name
        this.left_nav_name = res.data.left_nav_name
        this.top_nav_name = res.data.top_nav_name
        this.formText = res.data
      }).catch(() => {
        this.loading1 = false
      })
    },
    submit () {}
  }
}
</script>

